.link:hover {
  color: red;
}

.link {
  text-decoration: none;
}

.App {
  background: url("assets/GaneshPainting.png");
  background-size: 100%;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  max-width: 1450px !important;
  position: absolute !important;
  min-height: 100vh;
  min-width: 100vh;
  width: 100vw !important;
  height: 100vh !important;
}

h1,
li {
  color: red;
}

.App.noBackground {
  background: none;
}
